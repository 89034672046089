.card-footer {
    .row {
        .form-group {
            margin-bottom: 0;
        }
    }
}

.custom-file-label::after {
    content: "Parcourir" !important;
}

.custom-control {
    &.custom-radio {
        input,
        label {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
