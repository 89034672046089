// Fonts
// @import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic");

// ROBVQ Font
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

@import "~@fortawesome/fontawesome-pro/css/all.css";

// Variables
@import "variables";
@import "colors";
@import "form";
@import "dashboard";
@import "csstooltip";

//Boostrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/bootstrap";

// // AdminLTE
@import "../../vendor/almasaeed2010/adminlte/build/scss/_adminlte.raw.scss";

// DataTables
// @import "~datatables.net-dt/css/jquery.dataTables.min.css";
@import "../../vendor/almasaeed2010/adminlte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css";
@import "../../vendor/almasaeed2010/adminlte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css";

@import "../../vendor/almasaeed2010/adminlte/plugins/datatables-buttons/css/buttons.bootstrap4.min.css";
@import "../../node_modules/datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css";

@import "datatables";

//WYSIWYG
@import "../../node_modules/summernote/dist/summernote-bs4.min.css";

@import "../../node_modules/cropperjs/dist/cropper.min.css";

// Bootstrap Dual List Box
@import "bootstrap4-duallistbox";

@import "daterangepicker";
@import "../../node_modules/bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css";
@import "../../node_modules/bs-stepper/dist/css/bs-stepper.min.css";

@import "general";

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.page-title {
    display: flex;
    @extend .mb-2;
    h1 {
        flex: 1 1 auto;
    }
}

.box-profile {
    .list-group-item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-content: center;
        i {
            align-self: center;
        }
    }
}

.alert-success {
    color: #ffffff;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

.layout-fixed .wrapper .sidebar {
    height: calc(100vh - (6.88rem + 1px));
}

.dataTables_processing.card {
    background-color: $color__ternary;
    color: white;
}

div.dataTables_wrapper div.dataTables_processing {
    position: relative;
    margin-top: -64px;
}

@keyframes blink {
    50% {
        color: transparent;
    }
}
.dataTables_processing.card {
    animation: 1s blink infinite;
}
.dataTables_processing.card:nth-child(2) {
    animation-delay: 250ms;
}
.dataTables_processing.card:nth-child(3) {
    animation-delay: 500ms;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $color__ternary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

.loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
}

.active .bs-stepper-circle {
    background-color: $color__primary;
}

.login-box,
.register-box {
    width: clamp(360px, 400px, 98vw);
}
