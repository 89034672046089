.img-container {
    max-width: 100%;
    height: auto;
    img {
        resize: both;
        width: 100%;
        height: auto;
    }
}

.clean-ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
    }
    &--inline {
        li {
            display: inline;
        }
    }
}

.grecaptcha-badge {
    visibility: hidden !important;
}

@for $i from 1 through 5 {
    %line-clamp-#{$i} {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $i;
        overflow: hidden;
    }
}

.force-break-word {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    hyphens: auto !important;
}

a.disabled {
    pointer-events: none;
    cursor: default;
    color: inherit;
}
.items-center {
    place-items: center;
}
.content-center {
    place-content: center;
}
.flex-auto {
    flex: 1 1 auto;
}

.d-flex-column-center {
    display: flex;
    flex-direction: column;
    place-items: center;
    place-content: center;
}

.flex-auto {
    flex: 1 1 auto;
}

$cursors: pointer, info, help, default, no-drop;
@each $cursor in $cursors {
    .hover-#{$cursor} {
        &:hover {
            cursor: #{$cursor} !important;
        }
    }
}

@for $i from 1 through 5 {
    .line-clamp-#{$i} {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $i;
        overflow: hidden;
    }
}
