.css-tooltip {
    position: relative;
    &:after {
        transition: opacity 0.95s, visibility 0.75s;
        position: relative;
        opacity: 0;
    }
    &[data-title]:hover::after,
    &[data-title][data-disabled]:hover::after {
        content: attr(data-title) !important;
        opacity: 1;
        position: absolute;
        background: rgba(0, 0, 0, 0.75);
        color: #ffffff;
        border-radius: 2px;
        padding: 10% 1em;
        box-shadow: 2px 2px 5px -2px rgba(157, 153, 153, 0.93);
        width: max-content;
        max-width: 80vw;
        bottom: 80%;
        right: 20%;
    }

    &[data-placement="top"]:after {
        bottom: 80% !important;
    }

    &[data-placement="bottom"]:after {
        bottom: -80% !important;
    }

    &[data-placement="right"]:after {
        bottom: 0% !important;
        left: 100% !important;
    }

    &[data-placement="left"]:after {
        bottom: 0% !important;
        right: 80% !important;
    }
}

button.css-tooltip[data-disabled] i {
    filter: invert(40%);
}
