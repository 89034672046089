.color-facebook {
    color: #4267B2;
}

.color-twitter {
    color: #00acee;
}
.color-youtube {
    color: #c4302b;
}
