.dataTables_wrapper {
    .dt-buttons {
        margin-bottom: 1rem;
    }
}

table.dataTable thead .sorting_desc,
table.dataTable thead .sorting {
    background-image: none !important;
}

#organizations-table,
.dataTables_wrapper {
    td {
        vertical-align: middle;
    }
    .project-actions,
    form {
        > .btn {
            height: 30px !important;
            width: 30px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
        }
    }
    .project-actions {
        &__btn-wrapper {
            display: flex;
            justify-content: space-evenly;
        }
    }
    .table-responsive {
        @include media-breakpoint-up(xl) {
            display: table !important;
        }
    }
    @include media-breakpoint-down(xs) {
        .pagination {
            justify-content: center !important;
            .paginate_button.page-item {
                &.next,
                &.previous {
                    display: none !important;
                }
            }
        }
    }

    .table-surveys {
        .sorting_asc::after,
        .sorting_asc::before,
        .sorting_desc::after,
        .sorting_desc::before {
            display: none !important;
        }
        &--row-group {
            tr.dtrg-group td {
                background-color: rgba(0, 0, 0, 0.05);
                color: #010101;
            }
        }
    }
}
