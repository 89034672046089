@mixin flex-wrap-fix($flex-basis, $max-viewport-width: 2000px) {
    flex-grow: 1;
    flex-basis: $flex-basis;
    max-width: 100%;
  
    $multiplier: 1;
    $current-width: 0px;
  
    @while $current-width < $max-viewport-width {
      $current-width: $current-width + $flex-basis;
      $multiplier: $multiplier + 1;
  
      @media(min-width: $flex-basis * $multiplier) {
        max-width: percentage(1/$multiplier);
      }
    }
  }

.dashboard-menu {
    &__section {
        @extend .card;
        margin: 0.5rem 0;

        .dashboard-menu__title {
            @extend .card-header;
        }
        .dashboard-menu__entries:first-child {
            border: 1px red solid;
        }
        .dashboard-menu__entries {
            @extend .card-body;
            display: flex;
            flex-wrap: wrap;

            .dashboard-menu__entry {
                @include flex-wrap-fix(170px);
                margin: 0.05rem 1rem;
                padding: 0.5rem 0.25rem;
                text-align: center;
                flex-basis: 100%;
                overflow-wrap: normal;

                &:hover {
                    color: #ffffff;
                    background-color: #14426f;

                    .badge-primary {
                        background-color: #ffffff;
                        color: #14426f;
                    }

                    a {
                        color: #ffffff;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
